<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="library">
      <div class="library-sidebar">
         <SideBar />
      </div>
      <div class="library__container">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <div class="library__body">
            <div class="library__body-top">
               <SectionTitle :title="title" subtitle="" />
               <ProfileNav />
            </div>
            <router-view />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
/*    components: { 
      SideBar, 
      ProfileNav, 
      SectionTitle, 
      footerSec 
   }, */
   data() {
      return {
         title: "Alerta Push",
      };
   },
   mounted() {
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
   },
};
</script>